/* UI changes on 25 APR  */

@media screen and (max-width: 1056px) {
  .user_card .ml-50 {
    margin-left: 0px !important;
  }

}

.data-tables .icon-style {
  font-size: 2rem;
  color: #003868;
  cursor: pointer;
}

.card_newview {
  width: 100%;
  background: #003868;
  padding: 20px;
  border-radius: 15px;
}

.card_newview p {
  margin-bottom: 5px;
  color: #ffff !important;
  font-weight: 500;
  font-size: 16px;
}

.title_name {
  font-size: 24px;
  font-weight: 700 !important;
}

.vieworder .details .projetname {

  font-size: 20px;
  margin-top: 20px;
  color: #003868;
  font-weight: 600;


}

.vieworder .header {
  font-weight: 500;
}

.vieworder .header span {
  color: #003868;
  font-weight: 500;
}

.vieworder .otherdetails p {

  font-size: 14px;
  font-weight: 500;

}

.vieworder .otherdetails p span {
  color: #003868;
}

.lg-mt-2 {
  margin-top: 15px !important;
}

.newtable_prject_view {
  background: #f4f4f4;
  border-radius: 10px;
  padding: 20px 35px;

}

.newtable_prject_view tr {
  border-bottom: none !important;
  border-style: none !important;
  border-color: rgb(43 119 4 / 0%) !important;
}

.newtable_prject_view td {
  border-bottom: none !important;
  border-style: none !important;
  border-color: rgb(43 119 4 / 0%) !important;
  font-weight: 400;
}

.back_table {
  background: #f5f5f5;
  border-radius: 10px;

}

.back_table tr {
  border-bottom: none !important;
  border-style: none !important;
  border-color: rgb(43 119 4 / 0%) !important;
}

.back_table td {
  border-bottom: none !important;
  border-style: none !important;
  border-color: rgb(43 119 4 / 0%) !important;
  font-weight: 400;
}

.clr_green {
  color: #003868 !important;
  font-weight: 500 !important;
}

.projectDetails_new {
  padding: 10px 25px;
  /* padding-bottom: 25px !important; */
}

.details_off p {
  color: #545454 !important;
  font-weight: 500;
  font-size: 17px;
}

/* .newtable_prject_view  table tr td:first-child{
width: 25%;
} */
/* .details_off{
  border-bottom: 1px solid #f5f5f5;
} */
.boder_bottom {
  /* border-bottom: 1px solid #d4d4d4; */
  padding-bottom: 25px;
}

.details_off {
  border-bottom: 2px solid #f4f4f4;
}

.dates p {
  color: #003868 !important;
  font-size: 20px;
  font-weight: 600;
}

.dates h6 {
  margin-top: 5px;
  font-size: 17px;
  font-weight: 500;
  color: #545454 !important;
  padding-left: 16px;
}

/* .project_data .rdt_TableHeadRow [data-column-id="7"] {
  flex-grow: 1.5 !important;

}

.project_data .rdt_TableBody [data-column-id="7"] {
  flex-grow: 1.5 !important;

} */

.rdt_TableBody .rdt_TableRow:hover {
  background-color: #eff3f9;
}

.react-datepicker__day--keyboard-selected {
  color: #fff !important;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #f9f9f9;
  color: #000;
  border: 1px solid #dddddd !important;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker__month-select {
  padding: 2px 10px;
  border-radius: 5px;
}

.react-datepicker__header__dropdown {
  margin: 10px auto;
}

.react-datepicker__year-select {
  padding: 2px 10px;
  border-radius: 5px;
}

.data-tables .rdt_Table .rdt_TableHead .rdt_TableCol div input {

  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  border: 2px solid #e5e5e5 !important;
  height: 27px !important;
  margin-bottom: 10px !important;
  padding: 6px !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
  -webkit-transition: 0.5s;
  transition: 0.3s;
  outline: none;
  margin-right: 30px;
  width: 5rem !important;
  display: flex !important;
}

.data-tables .rdt_Table .rdt_TableHead .rdt_TableCol div input:focus {
  border: 2px solid #777 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;

}

.status_dot {
  display: inline-block;
  height: 8px;
  width: 8px;
  margin: 0px 4px 0px 0px;
  background: #fff;
  border-radius: 50%;

}

.action_button {
  background: #db600e;
  display: inline;
  float: left;
  color: #fff;
  padding: 5px 8px;
  border-radius: 10px;
  font-size: 16px !important;
  cursor: pointer;
  white-space: nowrap;
  /* font-size: 13px !important; */

}

.action_button:hover {
  color: #f5f5f5;
}

.action_button i {
  color: #fff !important;
  font-size: 16px !important;
}

.action_button i:hover {
  color: #f5f5f5;
}

.newtable .rdt_TableHeadRow {
  background: #132b5e !important;
  padding-top: 10px !important;
  padding: 5px 10px !important;
  border-radius: 10px 10px 0px 0px !important;
  color: #fff;
  min-height: 2rem !important;
  height: 2.5rem;
}

.newtable .rdt_TableBody {
  /* background: #f6f6f6 !important; */
  padding-top: 0px !important;
  padding: 6px 3px !important;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-img-section .sidebar-img-content {
  background-color: #ffffff !important;
  height: 100vh !important;
}

.f1 .f1-steps .f1-step.new-f1-step {
  width: 32% !important;
}

.methodology {
  font-size: 1.4rem;
  font-weight: bold;
  color: #000;
}

.f1 .f1-steps .f1-step {
  width: 25% !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #949292;
  transition: .4s;
  width: 49px;
  height: 27px;
}

.slider::before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 5.4px;
  background-color: white;
  transition: .4s;

}

input:checked+.slider {
  background-color: #132b5e !important;
}

input:focus+.slider {
  box-shadow: 0 0 1px #003868;
}

input:checked+.slider::before {
  -webkit-transform: translateX(45px);
  -ms-transform: translateX(45px);
  transform: translateX(26px);
}

/*------ ADDED CSS ---------*/
.on {
  display: none;
}

.on {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 30%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 70%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked+.slider .on {
  display: block;
}

input:checked+.slider .off {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.f1 .f1-steps .f1-step.active .f1-step-icon {
  font-size: 12px !important;
}

.f1 .f1-steps .f1-step .f1-step-icon {
  font-size: 12px !important;
  padding: 12px 2px !important;
  width: 48px !important;
  height: 48px !important;
}

.banner {
  /* background: url("../../assets/images/banner/signup-banner.png"); */
  background-repeat: no-repeat;
  background-size: 100%;
  background-size: cover;
  background-position-x: right;
  min-height: 100vh;
  height: auto;
}

.logo-wrapper img {
  width: 9rem;
  margin-top: -1rem;
}

.box {
  border: 1px solid #eee;
  padding: 20px 10px;
}

.input-calendar-ico {
  float: right;
  margin-top: -2rem;
  position: relative;
  margin-right: 1rem;
}

.thead-light {
  background: #b4b2ba;
  font-size: 16px;
}

.img-fluid {
  width: 8rem;
}

.green-card {
  /* background: #2b7704 !important; */
  border-radius: 1rem;
}

.green-card h3,
.green-card p {
  color: #ffff !important;
}

.show-icon {
  color: #898989;
  height: 20px !important;
  width: 20px !important;
}

.social-icons {
  font-size: 30px !important;
}

.required {
  color: red;
}

.f-16 {
  font-size: 16px;
}

.table-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.action-icon {
  font-size: 10px;
  color: #37474F;
  width: 18px !important;
  margin-top: 10px;
}

H1:hover,
H2:hover,
H3:hover,
H4:hover,
H5:hover,
H6:hover,
P:hover,
LI:hover {
  cursor: default;
}

.text-color {
  color: #003868
}

.ml-55 {
  margin-left: 55rem;
  display: flex;
}

.ml-20 {
  margin-left: 20rem;
}

.f-15 {
  font-size: 15px;
}

.mtop-2 {
  margin-top: 2rem;
}

.ellips {
  position: absolute;
  top: 30px;
  right: 30px;
  opacity: 0.7;
}

.fo-7 {
  opacity: 0.7;
}

.card-style {
  height: 15rem !important;
  cursor: pointer;
}

.add-card {
  margin-top: 7rem;
  margin-left: 7rem;
}

.card-bg {
  background-color: #DCEDC8 !important;
}

.card-menu {
  left: 40% !important;
  top: 50px !important
}

.x-button {
  position: absolute;
  top: 20px !important;
  right: 20px !important;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem !important;
}

.new_show_hide {
  float: right !important;
  display: inline-block !important;
  position: relative !important;
  top: -20px !important;
}

.hover-pointer {
  cursor: pointer !important;
}

.document-icon {
  font-size: 14px;
  color: #2b7704;
  width: 18px !important;
  margin-left: 2rem;
  cursor: pointer;
}

.paynow-btn {
  margin-bottom: 1rem;
  margin-left: 82%;
}

.documnt-p {
  color: #000000DE !important;
  margin-left: 10px;
}

.download-icon {
  font-size: 10px;
  color: #37474F;
  width: 18px !important;
  margin-left: 1.5rem !important;
}


.table-head,
.table-head th {
  border-bottom: none !important;
}

.disable-icon {
  pointer-events: none !important;
  color: #b4b2ba !important;
  cursor: none !important;
  font-size: 10px;
  width: 18px;
}

.ml-50 {
  margin-left: 50rem;
}

.reset-password {
  float: right !important;
  display: inline-block !important;
  position: relative !important;
  top: -25px !important;
}

.bottom-none {
  border-bottom-width: 0px !important;
}

.mr-2 {
  margin-right: 2rem;
}

.td-width {
  width: 140px !important;
}

.filter-toggle-label {
  margin-left: 36rem;
  margin-top: 0.8rem;
}

.tile-font-size {
  font-size: 1.4rem;
  color: #ffffff !important;
}

.no-wrap {
  white-space: nowrap;
}

.mtop-1 {
  margin-top: 1rem;
}

.rdt_TableHeadRow {
  display: flex !important;
  align-items: baseline !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.td-bottom {
  border-bottom-width: 0px !important;
}

.br-10 {
  border-radius: 10px !important;
}

.send-icon {
  border-radius: 60px !important;
  margin-bottom: 0px !important;
  margin-top: 7.5rem;
}

.p-10 {
  padding: 10px !important;
}

.send-svg {
  margin-left: 5px;
  cursor: pointer;
}

.border_none th {
  border-bottom: none !important;
}

.border_none_data td {
  border-bottom: none !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.justify-between {
  justify-content: space-between !important;
}

.border-none {
  border-bottom: none !important;
}


/* @media screen and (max-width:1150px) {
  .responsive-view .geknmz {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .responsive-view .gwbdqO {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
} */

@media screen and (max-width:1024px) {
  .responsive-view .hbotQl {
    padding-right: 20px !important;
  }
}


.trans-button {
  position: absolute;
  right: 37px;
}

.mtop-34 {
  margin-top: 34px !important;
}

.text-right {
  text-align: right;
}

.react-dual-listbox .rdl-move-all-right,
.react-dual-listbox .rdl-move-all-left {
  display: none;
}

.shadow_apply .rdl-filter,
.rdl-control {
  border-radius: 10px !important;
  border: none !important;
  box-shadow: 1px 3px 9px 0px rgba(0, 0, 0, 0.20) !important;
  overflow: auto !important;
}

.shadow_apply .rdl-actions {
  margin: 0 26px !important;
}

.shadow_apply .rdl-control option {
  font-size: 16px !important;
  padding: 10px 14px;
  font-weight: 500 !important;
}

.shadow_apply .rdl-control {
  padding: 7px 0 0 0 !important;
}

.shadow_apply .rdl-btn {
  padding: 8px 14px;
  background: #ebebeb6e;
}

.mt-40 {
  margin-top: 40px !important;
}

.ml-6 {
  margin-left: 6rem !important;
}

.text-left {
  text-align: left !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

/* @media screen and (min-width:1500px) {
  .wallet-details .hbotQl {
    word-break: break-word !important;
    white-space: normal !important;
  }
}

@media screen and (max-width:1500px) {
  .wallet-details .hbotQl {
    word-break: break-word !important;
    white-space: normal !important;
  }
} */

.mr-1 {
  margin-right: 1rem;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.w-76 {
  width: 76% !important;
}

.trading_orders button {
  font-size: 14px;
}

.trading-sell {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 90px;
  font-size: 14px !important;
  height: 34px;
}

.w_46 {
  width: 46% !important;
}

.money-value {
  text-align: right;
  margin-left: 2rem;
}


.trading-view .WQNxq {
  overflow-x: hidden !important;
}


.trading-view .gwbdqO {
  min-width: 140px !important;
}

.trading-view .chVlNX {
  min-width: 140px !important;
}


.title-color {
  color: #003868 !important;
}

.sale-chart-card {
  height: 10rem !important;
}

.sale-card-title {
  color: #fff;
  font-size: 20px;
}

.sale-value {
  color: #fff;
  font-size: 30px;
}

.credit-card-icon {
  color: #fff;
  font-size: 40px;
  height: 40px;
  width: 40px;
  align-items: self-start;
  justify-content: start;
  margin-bottom: 3rem;
  margin-left: 2rem
}

.btop-card {
  border-top: 6px solid !important;
}

.radio-but {
  margin-top: 0.75rem;
}

.view-page-table {}

.view-page-table table tr td {
  font-weight: 400 !important;
}

.plus-icon {
  height: 50px !important;
  width: 50px !important;
  fill: #003868;
  stroke-width: 1;
  color: #fff
}

.ml-46 {
  margin-left: 46rem;
}

.fw-td-bold {
  font-weight: bold !important;
}

.edit-icon {
  font-size: 10px;
  color: #003868;
  width: 18px !important;
  margin-top: 10px;
}

.toast-width {
  width: 400px !important;
}


/* UI Changes */


.trading_orders .sc-fLcnxK {

  width: 95% !important;

}

.trading_orders .rdt_TableHeadRow [data-column-id="2"] {
  flex-grow: 0.6 !important;
}

.trading_orders .rdt_TableBody [data-column-id="2"] {
  flex-grow: 0.6 !important;
}

.trading_orders .rdt_TableHeadRow [data-column-id="4"] {
  flex-grow: 0.5 !important;
}

.trading_orders .rdt_TableBody [data-column-id="4"] {
  flex-grow: 0.5 !important;
}

.trading_orders .rdt_TableHeadRow [data-column-id="5"] {
  flex-grow: 0.5 !important;
}

.trading_orders .rdt_TableBody [data-column-id="5"] {
  flex-grow: 0.5 !important;
}

.trading_orders .rdt_TableHeadRow [data-column-id="6"] {
  flex-grow: 0.6 !important;
}

.trading_orders .rdt_TableBody [data-column-id="6"] {
  flex-grow: 0.6 !important;
}

/* .trading_orders .rdt_TableHeadRow [data-column-id="1"] {
  flex-grow: 0.3 !important;
}

.trading_orders .rdt_TableBody [data-column-id="1"] {
  flex-grow: 0.3 !important;

}



.trading_orders .rdt_TableHeadRow [data-column-id="3"] {
  flex-grow: 0.3 !important;
}

.trading_orders .rdt_TableBody [data-column-id="3"] {
  flex-grow: 0.3 !important;

}

.trading_orders .rdt_TableHeadRow [data-column-id="6"] {
  flex-grow: 0.2 !important;
  padding-right: 10px;
}

.trading_orders .rdt_TableBody [data-column-id="6"] {
  flex-grow: 0.3 !important;

}

.trading_orders .rdt_TableHeadRow [data-column-id="7"] {
  flex-grow: 0.2 !important;
}

.trading_orders .rdt_TableBody [data-column-id="7"] {
  flex-grow: 0.2 !important;

}

.trading_orders .rdt_TableHeadRow [data-column-id="8"] {
  flex-grow: 0.3 !important;
}

.trading_orders .rdt_TableBody [data-column-id="8"] {
  flex-grow: 0.3 !important;
} */


.wallet-det .rdt_TableHeadRow [data-column-id="2"] {
  flex-grow: 0.4 !important;
}

.wallet-det .rdt_TableBody [data-column-id="2"] {
  flex-grow: 0.4 !important;
}

.wallet-det .rdt_TableHeadRow [data-column-id="3"] {
  flex-grow: 0.6 !important;
}

.wallet-det .rdt_TableBody [data-column-id="3"] {
  flex-grow: 0.6 !important;
}

.wallet-det .rdt_TableHeadRow [data-column-id="4"] {
  flex-grow: 0.6 !important;
}

.wallet-det .rdt_TableBody [data-column-id="4"] {
  flex-grow: 0.6 !important;
}

.wallet-det .rdt_TableHeadRow [data-column-id="5"] {
  flex-grow: 0.7 !important;
}

.wallet-det .rdt_TableBody [data-column-id="5"] {
  flex-grow: 0.7 !important;
}

.wallet-det .rdt_TableHeadRow [data-column-id="8"] {
  flex-grow: 0.6 !important;
}

.wallet-det .rdt_TableBody [data-column-id="8"] {
  flex-grow: 0.6 !important;
}

.wallet-det .rdt_TableHeadRow [data-column-id="7"] {
  flex-grow: 0.4 !important;
}

.wallet-det .rdt_TableBody [data-column-id="7"] {
  flex-grow: 0.4 !important;
}

.wallet-det .rdt_TableHeadRow [data-column-id="6"] {
  flex-grow: 0.3 !important;
}

.wallet-det .rdt_TableBody [data-column-id="6"] {
  flex-grow: 0.3 !important;
}

.mbottom-2 {
  margin-bottom: 2rem;
}

.trash-icon {
  font-size: 10px;
  color: #DC3545;
  width: 18px !important;
  margin-top: 10px;
}


.react-datepicker__year-dropdown .react-datepicker__navigation {
  background-color: none !important;
}

.notes-card {
  box-shadow: 0px 0px 0px 1px rgba(12, 12, 12, 0.2) !important;
  margin-top: 2rem !important;
}


@media screen and (max-width:690px) {
  .trading-view .sc-dmctIk.WQNxq {
    overflow-x: scroll !important;
  }
}

@media screen and (min-width: 1199px) and (max-width: 1320px) {
  .sale-content h3 {
    font-size: 20px !important;
  }
}

.new_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100vh;
  width: 100%;
  background: #EBEFF6;
  z-index: 4;
}

.order-trading .hZPyfA {
  flex-grow: 1 !important;
}

.process-button {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 10px !important;
  font-weight: 400 !important;
  font-size: 13px !important;
}

.fill-button {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 10px !important;
  font-weight: 400 !important;
  font-size: 13px !important;
}

.wallet-icon {
  height: 80px !important;
  width: 80px !important;
}

@media screen and (max-width:768px) {
  .trading_orders .rdt_TableHeadRow [data-column-id="1"] {
    flex-grow: 0.8 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="1"] {
    flex-grow: 0.8 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="2"] {
    flex-grow: 0.8 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="2"] {
    flex-grow: 0.8 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="3"] {
    flex-grow: 0.6 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="3"] {
    flex-grow: 0.6 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="4"] {
    flex-grow: 1 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="4"] {
    flex-grow: 1 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="5"] {
    flex-grow: 1 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="5"] {
    flex-grow: 1 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="6"] {
    flex-grow: 0.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="6"] {
    flex-grow: 0.5 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="7"] {
    flex-grow: 1.2 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="7"] {
    flex-grow: 1.2 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="7"] button {
    /* display: flex; */
    width: 112px;
    font-size: 11px;
    height: 34px;
    padding: 10px;
  }
}

.view-details {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 10px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-weight: 400 !important;
}

.order-status {
  display: flex;
  justify-items: baseline;
  align-items: baseline;
}

.order-select {
  width: 20% !important;
  margin-left: 0.5rem !important;
}

.rdt_TableHeadRow {
  text-align: center !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}


@media screen and (max-width:560px) {
  .sale-chart-card {
    margin: 10px 20px;
  }

  .update_btn {
    margin-top: 10px !important;
    margin-left: 0 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="1"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="1"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="2"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="2"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="3"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="3"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="4"] {
    flex-grow: 10.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="4"] {
    flex-grow: 10.5 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="5"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="5"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="6"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="6"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="7"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="7"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="8"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="8"] {
    flex-grow: 6.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="7"] button {
    /* display: flex; */
    width: 75px;
    font-size: 10px;
    height: 30px;
    padding: 3px !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="8"] button {
    /* display: flex; */
    width: 75px;
    font-size: 10px;
    height: 30px;
    padding: 3px !important;
  }

  .page-wrapper .page-body-wrapper .card {
    overflow-x: scroll !important;

  }

}

@media screen and (max-width:640px) {

  .sale-chart-card,
  .wallet_card,
  .details_card {
    margin: 10px 20px;
  }

  .wallet-details .rdt_Table .rdt_TableHead .rdt_TableHeadRow .bQdYbR {
    padding: 10px 20px !important;

  }

  .wallet-details .rdt_Table .rdt_TableHead .rdt_TableHeadRow .bQdYbR {
    padding: 10px 20px !important;

  }

  .wallet-details .rdt_TableBody .bQdYbR {
    padding: 10px 20px !important;
  }

  .trading_orders .rdt_Table .rdt_TableHead .rdt_TableHeadRow .bQdYbR {
    padding: 10px 20px !important;

  }

  .trading_orders .rdt_TableBody .bQdYbR {
    padding: 10px 20px !important;
  }

  .trading_orders .rdt_TableHeadRow [data-column-id="5"] {
    flex-grow: 8 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="5"] {
    flex-grow: 8 !important;
  }

  .newtable_prject_view table td {
    font-size: 13px !important;
  }

  .project_data .rdt_TableHeadRow [data-column-id="6"] {
    flex-grow: 1.5 !important;
  }

  .project_data .rdt_TableBody [data-column-id="6"] {
    flex-grow: 1.5 !important;
  }

}

@media screen and (max-width: 460px) {
  .trading_orders .rdt_TableHeadRow [data-column-id="4"] {
    flex-grow: 20.5 !important;
  }

  .trading_orders .rdt_TableBody [data-column-id="5"] {
    flex-grow: 20.5 !important;
  }
}

.emission-table {}

.emission-table .chVlNX {
  min-width: 100px !important;
}

.emission-table .gwbdqO {
  min-width: 100px !important;
}

.node circle {
  fill: #132b5e !important;
}

.node text {
  white-space: pre-wrap !important;
  padding-bottom: 5% !important;
}

.orgSummary-chart {
  margin-top: 5rem;
}

.orgSummary-chart svg {
  width: 100% !important;
}

.simplebar-content-wrapper .sidebar-list .sidebar-link .menu-title {
  display: flex;
}

.simplebar-content-wrapper .sidebar-list .sidebar-link .menu-title span {
  white-space: nowrap;
}

div.modal.fade.show div.modal-body>table>tbody>tr td>p {
  display: block !important;
}

@media screen and (min-width: 1045px) {
  .responsive-view .chVlNX {
    padding-right: 30px !important;
  }
}

#org-table th:last-child,
#org-table td:last-child {
  width: 10rem;
}

#org-table thead {
  background: #f6f6f6;
  padding: 10px 10px;
  border-radius: 10px 10px 0px 0px;
}

.creeua p:first-child {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iBSWG p:first-child {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trading_orders .creeua p:first-child {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trading_orders .iBSWG p:first-child {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order-trading .dlUCce {
  overflow: hidden !important;
}

.order-trading .ftDSoT {
  min-width: 120px !important;
}

.order-trading .creeua {
  min-width: 120px !important;
}

.order-trading .uINPX {
  min-width: 120px !important;
}

.order-trading .iBSWG {
  min-width: 120px !important;
}

.mb-0 {
  margin-bottom: 0px;
}

.margin-inline {
  margin-inline-start: auto
}

.mb-22px {
  margin-bottom: 22px;
}

.margin-inline-end {
  margin-inline-end: auto !important;
}

/* .card .data-tables p:first-child {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.rage-input-label {
  margin-top: 1rem;
}

.project-progress .f1 .f1-steps .f1-step.new-f1-step {
  width: 32% !important;
}


.project-progress .f1 .f1-steps .f1-step {
  width: 11% !important;
}

.project-progress .f1 .f1-steps .f1-step.active .f1-step-icon {
  font-size: 10px !important;
}

.project-progress .f1 .f1-steps .f1-step .f1-step-icon {
  font-size: 12px !important;
  padding: 9px 2px !important;
  width: 40px !important;
  height: 40px !important;
}

.project-progress .f1 .f1-steps .f1-progress {
  position: absolute;
  top: 70px;
  width: 100%;
  height: 12px;
  background: rgba(43, 119, 4, 0.1019607843);
  border-radius: 8px;
}

.project-progress .f1 .f1-steps .f1-progress .f1-progress-line {
  height: 12px;
  background-image: linear-gradient(to right, #003868, #6a4084, #bc3e7a, #ee5850, #132b5e);
  border-radius: 8px;
}

.project-progress .h-11 {
  height: 9rem !important;
  padding-top: 56px;
}

.project-progress .top-p {
  margin-top: -35px;
  margin-bottom: 0px;
}

.mt-19 {
  margin-top: 19px !important;
}

.mt-24 {
  margin-top: 24px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-neg-3 {
  margin-top: -3px;
}

.carbon-emission .data-tables .iBSWG {
  min-width: 100px !important;
}

.carbon-emission .data-tables .uINPX {
  min-width: 100px !important;
}

.carbon-emission .data-tables .ftDSoT {
  min-width: 100px !important;
}

.carbon-emission .data-tables .creeua {
  min-width: 100px !important;
}

.data-tables .iBSWG {
  padding-left: 8px;
}

/* .location-list .data-tables .creeua {
  min-width: 57px !important;
} */

.location-list .data-tables .iBSWG {
  min-width: 57px !important;
}

.data-tables .creeua {
  padding-left: 8px;
  min-width: 60px !important;
}

.status-btn {
  margin-bottom: 1rem;
  float: right;
}

.project-transition {
  position: sticky;
  max-height: 30rem;
  overflow-y: auto;
}

.w-17 {
  width: 17rem;
}

.substate-tooltip {
  background-color: #003868 !important;
  color: #fff !important;
}

.progress-bar-align {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu-li {
  border-bottom: 2px solid #89898961 !important;
  margin-top: 0px;
  margin-bottom: 0px;
}

ul.simple-list.sidebar-submenu.d-block.list-group {
  background-color: #f4f4f4;
  border-radius: 0px;
}

.project-init .f1 .f1-steps .f1-progress {
  position: absolute;
  top: 19px;
  width: 100%;
  height: 12px;
  background: rgba(43, 119, 4, 0.1019607843);
  border-radius: 8px;
}

.project-init .f1 .f1-steps .f1-progress .f1-progress-line {
  height: 12px;
  background-image: linear-gradient(to right, #003868, #6a4084, #bc3e7a, #ee5850, #132b5e);
  border-radius: 8px;
}

.mb-15 {
  margin-bottom: 15px !important;
}

/* .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content>li a svg {
  width: 18px;
  margin-right: 10px;
  vertical-align: bottom;
  float: none;
  stroke-width: 2.3px;
  color: #132b5e !important;
  transition: all 0.3s ease;
  margin-bottom: 5px;
} */

.active-svg {
  color: #003868 !important;
}

.wallet-details .rdt_TableHeadRow,
.trading-view .rdt_TableHeadRow,
.trading_orders .rdt_TableHeadRow {
  background: #003868db !important;
  padding-top: 10px !important;
  padding: 10px 10px !important;
  border-radius: 10px 10px 0px 0px !important;
  color: #fff;
}

.wallet-details .rdt_TableBody,
.trading-view .rdt_TableBody,
.trading_orders .rdt_TableBody {
  /* background: #f6f6f6 !important; */
  padding-top: 0px !important;
  padding: 6px 3px !important;
}

.mr-13 {
  margin-right: 13px;
}

.table-header-bg th {
  background: #132b5e !important;
}

.table-header-bg th:nth-child(1) {
  border-top-left-radius: 5px;
}

.table-header-bg th:nth-child(3) {
  border-top-right-radius: 5px;
}

.table-header-bg {
  padding-top: 10px !important;
  padding: 10px 10px !important;
  border-radius: 10px 10px 0px 0px !important;
  text-align: center;
  color: #fff !important;
}

.table-body {
  padding-top: 0px !important;
  padding: 6px 3px !important;
  text-align: center;
}

.text-center {
  text-align: center !important;
}

.p-0 {
  padding: 0px !important;
}

.profile-icon {
  height: 45px !important;
  width: 45px !important;
  fill: #132b5e;
}

.notes-table-header {
  background: #003868db !important;
  border-radius: 10px 10px 0px 0px !important;
}

.project-transition-table {
  background: #f4f4f4 !important;
  border-radius: 10px 10px 0px 0px !important;
}

.notes-table-body {
  padding-top: 0px !important;
  padding: 6px 3px !important;
}

.notes-card .table .th {
  color: #fff;
}

.modal-footer {
  justify-content: flex-start !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.modal-body {
  padding: 25px !important;
}

.modal-header {
  color: #fff !important;
  background: #132b5e;
}

.modal-title-color {
  color: #fff !important;
}

.f-17 {
  font-size: 17px;
}

.w-12 {
  width: 12rem;
}

.w-15 {
  width: 15rem;
}

.pl-4 {
  padding-left: 56px;
}

.module-subscribe .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #949292;
  transition: .4s;
  width: 49px;
  height: 27px;
}

.module-subscribe .slider::before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 5.4px;
  background-color: #003868;
  transition: .4s;
}

.module-subscribe .slider::after {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 5.4px;
  background-color: #fff;
  transition: .4s;
  border-radius: 50px;
}


.module-subscribe input:checked+.slider {
  background-color: #fff !important;
}

.module-subscribe input:focus+.slider {
  box-shadow: 0 0 1px #fff;
}

.pb-14 {
  padding-bottom: 15px !important;
}

.card-height {
  height: 385px !important;
}

.rm-margin {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.fw-4 {
  font-weight: 400 !important;
}

.pb-0 {
  padding-bottom: 0px;
}

.css-t3ipsp-control {

  box-shadow: 0 0 0 1px #003868 !important;
}

.css-t3ipsp-control:active {
  box-shadow: 0 0 0 1px #003868 !important;
}

.css-t3ipsp-control:hover {
  border-color: #003868 !important;
}

.css-10wo9uf-option :hover {
  background-color: #003868 !important;
}

.css-tr4s17-option {
  background-color: #003868 !important;
}

/* .css-1nmdiq5-menu :hover {
  background-color: #003868 !important;
} */

.tooltip-style {
  border-radius: 7px !important;
  background-color: #0038687d !important;
  color: #fff !important;
  opacity: 0.8 !important;
}

.tooltip-arrow {
  background-color: #003868e6 !important;
}

.menu-tooltip {
  border-radius: 8px !important;
  background-color: #003868 !important;
  color: #fff !important;
  opacity: 1 !important;
}

.mtop-hf {
  margin-top: 0.5rem;
}

#reportContainer {
  height: 90vh;
}

.pl-21 {
  padding-left: 21px;
}

.mb-18 {
  margin-bottom: 18px !important;
}

.mr-36 {
  margin-right: 36px;
}

.mr-21 {
  margin-right: 21px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  color: #fff !important;
}

.table-txt-color {
  color: #000 !important;
}

.data-tables .ftDSoT {
  margin-top: 3px;
  min-width: 56px !important;
}

.data-tables .uINPX {
  margin-top: 3px;
  min-width: 56px !important;
}

.order-trading .data-tables .uINPX {
  margin-top: 3px;
  min-width: 115px !important;
}

.order-trading .data-tables .iBSWG {
  padding-left: 8px;
  min-width: 120px !important;
}

.order-trading .data-tables .ftDSoT {
  margin-top: 3px;
  min-width: 115px !important;
}

.order-trading .data-tables .creeua {
  padding-left: 8px;
  min-width: 120px !important;
}

.desc-card-height {
  height: 227px !important;
}

.pt-15 {
  padding-top: 15px;
}

.data-tables .iBSWG {
  padding-left: 8px;
  min-width: 60px !important;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.placeholder-text-color {
  color: #898989 !important;
  font-size: 13px;
  display: block;
}

.order-transition-but {
  position: absolute;
  right: 10px;
}

.credit-rates .rdt_TableHeadRow [data-column-id="8"] {
  flex-grow: 0.7 !important;
}

.credit-rates .rdt_TableBody [data-column-id="8"] {
  flex-grow: 0.7 !important;
}

.credit-rates .rdt_TableHeadRow [data-column-id="7"] {
  flex-grow: 0.7 !important;
}

.credit-rates .rdt_TableBody [data-column-id="7"] {
  flex-grow: 0.7 !important;
}

.credit-rates .rdt_TableHeadRow [data-column-id="6"] {
  flex-grow: 0.7 !important;
}

.credit-rates .rdt_TableBody [data-column-id="6"] {
  flex-grow: 0.7 !important;
}

.credit-rates .rdt_TableHeadRow [data-column-id="5"] {
  flex-grow: 0.7 !important;
}

.credit-rates .rdt_TableBody [data-column-id="5"] {
  flex-grow: 0.7 !important;
}

.credit-rates .rdt_TableHeadRow [data-column-id="4"] {
  flex-grow: 0.8 !important;
}

.credit-rates .rdt_TableBody [data-column-id="4"] {
  flex-grow: 0.8 !important;
}

.mr-27 {
  margin-right: 27rem;
}

.exchange-rates .rdt_TableHeadRow [data-column-id="4"] {
  flex-grow: 0.7 !important;
}

.exchange-rates .rdt_TableBody [data-column-id="4"] {
  flex-grow: 0.7 !important;
}

.exchange-rates .rdt_TableHeadRow [data-column-id="5"] {
  flex-grow: 0.7 !important;
}

.exchange-rates .rdt_TableBody [data-column-id="5"] {
  flex-grow: 0.7 !important;
}

.modal-spinner {
  margin-top: 1.5rem;
  margin-left: 22rem;
}

.highlighted-rate {
  color: #2b7704 !important;
  font-weight: 600;
}

.manage-asm-but {
  position: absolute;
  right: 20px;
}

.aso-dropdown {
  position: absolute;
  right: 20px;
  width: 25%;
}

.disable-field {
  background-color: #f3f3f3 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="1"] {
  flex-grow: 0.8 !important;
}

.ev-charges .rdt_TableBody [data-column-id="1"] {
  flex-grow: 0.8 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="2"] {
  flex-grow: 1 !important;
}

.ev-charges .rdt_TableBody [data-column-id="2"] {
  flex-grow: 1 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="3"] {
  flex-grow: 1 !important;
}

.ev-charges .rdt_TableBody [data-column-id="3"] {
  flex-grow: 1 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="4"] {
  flex-grow: 0.8 !important;
}

.ev-charges .rdt_TableBody [data-column-id="4"] {
  flex-grow: 0.8 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="5"] {
  flex-grow: 1.6 !important;
}

.ev-charges .rdt_TableBody [data-column-id="5"] {
  flex-grow: 1.6 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="6"] {
  flex-grow: 1 !important;
}

.ev-charges .rdt_TableBody [data-column-id="6"] {
  flex-grow: 0.8 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="7"] {
  flex-grow: 1 !important;
}

.ev-charges .rdt_TableBody [data-column-id="7"] {
  flex-grow: 1 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="8"] {
  flex-grow: 0.5 !important;
}

.ev-charges .rdt_TableBody [data-column-id="8"] {
  flex-grow: 0.5 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="9"] {
  flex-grow: 0.5 !important;
}

.ev-charges .rdt_TableBody [data-column-id="9"] {
  flex-grow: 0.5 !important;
}

.ev-charges .rdt_TableHeadRow [data-column-id="10"] {
  flex-grow: 0.5 !important;
}

.ev-charges .rdt_TableBody [data-column-id="10"] {
  flex-grow: 0.5 !important;
}


.ev-charges .data-tables .creeua {
  padding-left: 13px !important;
  min-width: 60px !important;
}

.ev-charges .data-tables .iBSWG {
  padding-left: 13px;
}

.ml-20px {
  margin-left: 20px !important;
}


.active-switch {
  float: right;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.active-switch-lable {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 600;
}

.aso-org-dropdown {
  width: 25%;
  float: right;
}

.ev-icon {
  font-size: 25px;
  color: green;
  width: 25px !important;
  margin-top: 10px;
  margin-left: 0px;
}

.project_data .rdt_TableHeadRow [data-column-id="8"] {
  flex-grow: 1 !important;
}

.project_data .rdt_TableBody [data-column-id="8"] {
  flex-grow: 1 !important;
}

.project_data .rdt_TableHeadRow [data-column-id="7"] {
  flex-grow: 1.3 !important;
}

.project_data .rdt_TableBody [data-column-id="7"] {
  flex-grow: 1.3 !important;
}

.project_data .rdt_TableHeadRow [data-column-id="6"] {
  flex-grow: 1.3 !important;
}

.project_data .rdt_TableBody [data-column-id="6"] {
  flex-grow: 1.3 !important;
}

.project_data .rdt_TableHeadRow [data-column-id="1"] {
  flex-grow: 1.3 !important;
}

.project_data .rdt_TableBody [data-column-id="1"] {
  flex-grow: 1.3 !important;
}

.disable-ev-icon {
  pointer-events: none !important;
  color: #b4b2ba !important;
  cursor: none !important;
  font-size: 25px;
  width: 25px;
  margin-top: 3px;
  margin-left: 0px;
}

.paynow-btn {
  margin-bottom: 1rem;
  margin-left: 81%;
}

.fl-right {
  float: right !important;
}

.ag-header-cell {
  font-size: 15px;
  color: #555;
}

.ev-consumption .ag-theme-material .ag-cell-inline-editing {
  padding: 0px;
  height: 46px;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #888;
  border-radius: 5px;
}

.ev-consumption .ag-theme-material input[class^=ag-][type=number] {
  border-bottom: none !important;
  border-bottom-color: #fff;
  padding-bottom: 0px;
}

.ev-consumption .ag-theme-material .row-style {
  background-color: #fafafa;
}

.ev-consumption .ag-theme-material .text-input-field {
  display: flex;
  padding: 0px;
  height: 40px;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #888;
  border-radius: 5px;
  margin-top: 5px;
  padding-left: 10px;
  align-items: center;

}

.ev-consumption .ag-theme-material .ag-has-focus .ag-cell-inline-editing {
  border-color: #003868 !important;
}

.ag-header {
  background-color: #132b5e !important;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.ag-theme-material {
  --ag-header-background-color: #132b5e !important;
  width: auto !important;
}

@media (max-width: 768px) {
  .ev-consumption .ag-theme-alpine {
    height: 800px;
  }
}

.ag-header-cell:not(.ag-header-cell-auto-height) .ag-header-cell-comp-wrapper,
.ag-header-icon {
  color: #fff !important;
}

.ag-header-cell:not(.ag-column-resizing)+.ag-header-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover,
.ag-header-cell:not(.ag-column-hover):first-of-type:not(.ag-header-cell-moving):hover {
  background-color: #132b5e !important;
}

.ag-header-cell-menu-button:not(.ag-header-menu-always-show) {
  opacity: 1;
}

.ev-consumption .ag-header {
  width: 86% !important;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

@media (max-width: 1080px) {
  .ev-consumption .ag-header {
    width: 100% !important;
  }
}

@media (max-width: 1280px) {
  .ev-consumption .ag-header {
    width: 96% !important;
  }
}

.mb-1 {
  margin-bottom: 1rem;
}

.ag-header-cell {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ag-cell {
  display: inline-flex !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.waste-collection .ag-theme-material .ag-cell-inline-editing {
  padding: 0px 0px 0px 0px !important;
  height: 46px;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #888;
  border-radius: 5px;
}

.waste-collection .ag-theme-material input[class^=ag-][type=number] {
  border-bottom: none !important;
  border-bottom-color: #fff;
  padding-bottom: 0px;
}

.waste-collection .ag-theme-material .row-style {
  background-color: #fafafa;
}

.waste-collection .ag-theme-material .text-input-field {
  display: flex;
  padding: 0px;
  height: 40px;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #888;
  border-radius: 5px;
  margin-top: 5px;
  padding-left: 10px;
  align-items: center;
  width: 177px !important;
}

.waste-collection .ag-theme-material .ag-has-focus .ag-cell-inline-editing {
  border-color: #003868 !important;
}

.ag-select .ag-picker-field-wrapper {
  padding: 0px 0px 0px 5px !important;
  height: 46px;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid #888;
  border-radius: 5px;
  cursor: default;
}

.waste-collection .ag-header {
  width: 70% !important;
}

.waste-collection .ag-paging-panel {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 55% !important;
}

.upload-icon {
  font-size: 46px;
  color: #37474F;
  width: 28px !important;
  margin-left: 33px;
}

.file-upload {
  margin-top: 0.5rem;
  margin-left: -1rem;
}

.customer-logo {
  width: 2.5rem;
  margin-right: 0.5rem;
}

/* container for all of the switch elements 
    - adjust "width" to fit the content accordingly 
*/
.switches-container {
  width: 10rem;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  border: 1px solid #132b5e;
  line-height: 2rem;
  border-radius: 3rem;
  margin-left: auto;
  margin-right: auto;
  margin-right: 0;
  background-color: #132b5e;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container>input[type="radio"] {
  visibility: hidden;
  position: absolute;
  top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: white;
  margin-bottom: 0;
}

/* switch highlighters wrapper (sliding left / right) 
  - need wrapper to enable the even margins around the highlight box
  */
.switch-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  padding: 0.15rem;
  /* z-index: 1; */
  transition: transform .5s cubic-bezier(.77, 0, .175, 1);
}

/* switch box highlighter */
.switches {
  border-radius: 3rem;
  background: white;
  height: 100%;
}

/* slide the switch box from left to right */
.switches-container>input[type="radio"]:nth-of-type(2):checked~.switch-wrapper {
  transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
/* .switches-container > input[type="radio"]:nth-of-type(1):checked~.switch-wrapper .switches div:nth-of-type(1) {
      opacity: 1;
  } */
/* toggle the switch box labels - second checkbox:checked - show second switch div */
/* .switches-container > input[type="radio"]:nth-of-type(2):checked~.switch-wrapper .switches div:nth-of-type(2) {
      opacity: 1;
  } */

.opacity-1 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.switches div {
  width: 100%;
  text-align: center;
  display: block;
  color: black;
  transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;
}

.header-color {
  color: #003868;
}

.subscription-table>tbody>tr>td,
.subscription-table>thead>tr>th {
  padding: 0.5rem !important;
}

.subscription-table>thead th:nth-child(2) {
  border-top-right-radius: 5px;
}

#customer-table td {
  padding-top: 0.7rem;
}

#customer-table>thead th:nth-child(3) {
  padding-right: 2rem;
}

.active-card {
  background: #bbd0e16e !important;
}

.inventory-container .page-title {
  padding-top: 0 !important;
  margin-left: -1.3rem !important;
}

.inventory-btn-container {
  margin-left: 4rem;
}

.inventory-container .carousel-control-next,
.inventory-container .carousel-control-prev {
  background: #132b5e;
  height: 89%;
  width: 2%;
}

.store-container .checkbox-animated {
  max-height: 22rem;
  overflow-y: auto;
  margin-bottom: 0 !important;
}

.label-active {
  background: #132b5e;
  color: white;
}

.device-span {
  padding: 0.3rem;
  background-color: #1f73b6ad;
  border-radius: 1rem;
  color: white;
  margin: 0.2rem;
}

.allocated-device-count {
  width: 100%;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
}

.subscription-p {
  margin-right: 0.2rem;
  font-size: 12px;
  background: #db600e;
  border-radius: 0.6rem;
  padding: 0.3rem;
  color: #f4f4f4;
  text-align: center;
}

#subscription-type {
  background-color: #132b5e;
  width: 11.5rem;
  border: none;
}

#subscription-type .nav-link {
  color: #f4f4f4;
}

#subscription-type .nav-link.active {
  margin-bottom: 0.1rem;
  margin-top: 0.1rem;
  border-radius: inherit;
  color: black;
  margin-left: 0.1rem;
}

#subscription-type .nav-link:hover {
  border: unset;
}

.center-logo {
  display: flex;
  justify-content: center;
}

.pb-10 {
  padding-bottom: 10px;
}

.mb-hfrm {
  margin-bottom: 0.5rem;
}

.justify-space-even {
  justify-content: space-evenly !important;
}

.nav-tabs .nav-link {
  border: var(--bs-nav-tabs-border-width) solid #132b5e !important;
}

.chip {
  background: #132b5e !important;
}

.highlightOption {
  background: #132b5e !important;
  color: #fff;
}

.device-table .ag-header {
  background-color: #324e89 !important;
}

/* .device-table .ag-header-cell:not(.ag-column-resizing)+.ag-header-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover, .ag-header-cell:not(.ag-column-hover):first-of-type:not(.ag-header-cell-moving):hover {
  background-color: #555555 !important;
} */

.explorationContainer .exploration {
  background-color: #fff !important;
}

.explorationContainer .exploration {
  background-color: #fff !important;
}

.explorationContainer .exploreCanvas .backgroundContainer .visualBackgroundContainer {
  background-color: #fff !important;
}

.backgroundContainer.visualBackgroundContainer.themableBackgroundColorSolid {
  background: #fff !important;
}

.notification-container {
  margin-top: 7rem !important;
}

.ag-header-cell:not(.ag-header-cell-auto-height) .ag-header-cell-comp-wrapper,
.ag-header-icon {
  color: #fff !important;
  white-space: normal;
}

.text-col-wrap {
  width: 130px !important;
  white-space: normal !important;
  line-height: 17px !important;
  margin-top: 6px !important;
}

.pin-icon {
  font-size: 20px;
  color: #003868;
  width: 18px !important;
  margin-top: 10px;
}

.ag-select .ag-picker-field-wrapper {
  height: 35px !important;
}


.ag-paging-panel>.ag-paging-page-size .ag-wrapper {
  min-width: 80px !important;
}

.mtop-0 {
  margin-top: 0px !important;
}

.ag-icon-desc,
.ag-icon-menu,
.ag-icon-asc {
  color: #fff !important;
}

.store-table .ag-row-hover:not(.ag-full-width-row)::before,
.ag-row-hover.ag-full-width-row.ag-row-group::before {
  position: unset !important;
}

.grand-total {
  position: absolute;
  right: 40px !important;
}

.justify-around {
  justify-content: space-around !important;
}

.model-scroll {
  max-height: 600px !important;
  overflow-y: scroll !important;
}

.store-profile {
  font-size: 20px;
  font-weight: 500;
  color: #555;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-top: 1rem;
  background: #5454;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.profile-card-padding {
  padding: 0px 0px 1rem 0px !important;
}

.border-bottom {
  padding-left: 0px !important;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #545454 !important;
}

.profile-title {
  font-size: 18px;
  font-weight: 500;
  color: #545454;
}

.sec-card-padding {
  padding: 0px 0px 10px 0px !important;
}

.mtop-10px {
  margin-top: 10px;
}

.profile-title a:hover {
  text-decoration: none;
  color: #ef6409 !important;
}

.edit-button {
  position: absolute;
  right: 27px;
}

#customer-table th {
  color: #fff;
}

.loading-text {
    margin-top: 4.5rem;
    position: absolute;
    width: 100%;
}


.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active i.fa-angle-down,
.sidebar-submenu i {
  color: #0a0911 !important;
}

.active-widget {
  border: solid #132b5e !important;
}

.justify-end {
  justify-content: end !important;
}

.mb-1rem {
  margin-bottom: 1rem !important;
}