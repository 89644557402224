/**=====================
    34. Sidebar CSS Start
==========================**/
%sidebar-after {
  position: absolute;
  // content: "";
  top: 48%;
  width: 6px;
  // border-top: 2px solid $dark-gray;
}
%horizontal-after {
  position: absolute;
  content: "";
  top: 49%;
  width: 5px;
  height: 1px;
  border-radius: 100%;
  background-color: #59667a;
}
%common {
  border-left-color: $dark-color;
  transition: $sidebar-transition;
}
%for-animated-hover {
  box-shadow: $main-header-shadow;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}
%for-animated-hover-box {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  border-radius: 5px;
  overflow: hidden;
}
%sidebar-icon {
  display: block;
  transform: rotateX(0deg);
  z-index: 1;
  background-color: $white;
  color: $light-background;
  font-size: 14px;
  opacity: 1;
  margin-top: -70px;
  padding: 10px 0;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
  position: absolute;
  width: 245px;
  margin-left: 115px;
}
.page-wrapper {
  position: relative;
  .page-header {
    max-width: 100vw;
    position: $main-header-position;
    top: $main-header-top;
    z-index: $main-header-z-index;
    transition: $sidebar-transition;
    background-color: $white;
    // box-shadow: $main-header-shadow;
    .header-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $header-wrapper-padding;
      position: relative;
      height: 28%;
      .search-full {
        background-color: $white;
        position: absolute;
        right: 0;
        z-index: 1;
        height: 0;
        width: 0;
        transform: scale(0);
        transition: all 0.3s ease;
        &.open {
          height: 100%;
          width: 100%;
          animation: zoomIn 0.5s ease-in-out;
          transform: scale(1);
          transition: all 0.3s ease;
        }
        input {
          line-height: 65px;
          padding-left: 60px;
          width: 100% !important;
          &:focus {
            outline: none !important;
          }
        }
        .form-group {
          .close-search {
            position: absolute;
            font-size: 15px;
            right: 30px;
            top: 30px;
            color: $dark-gray;
            cursor: pointer;
          }
          &:before {
            position: absolute;
            left: 30px;
            top: 27px;
            content: "\f002";
            font-size: 16px;
            color: $dark-gray;
            font-family: FontAwesome;
          }
        }
      }
      .nav-right {
        justify-content: flex-end;
        ul {
          li {
            svg {
              vertical-align: $header-wrapper-nav-icon-align;
              width: $header-wrapper-nav-icon-size;
              path {
                color: $header-wrapper-nav-icon-color;
              }
            }
          }
        }
        &.right-header {
          ul {
            li {
              .mode {
                cursor: pointer;
                width: 20px;
                text-align: center;
                i {
                  font-size: 18px;
                  font-weight: 600;
                  transition: all 0.3s ease;
                  &.fa-lightbulb-o {
                    font-weight: 500;
                    font-size: 22px;
                    transition: all 0.3s ease;
                    animation: zoomIn 300ms ease-in-out;
                  }
                }
              }
              .header-search {
                cursor: pointer;
              }
              .profile-media {
                img {
                  height: 40px;
                  width: auto;
                }
                .flex-grow-1 {
                  margin-left: 15px;
                  span {
                    font-weight: 500;
                  }
                  p {
                    font-size: 12px;
                    line-height: 1;
                    color: rgba($theme-body-font-color, 0.7);
                  }
                }
              }
            }
          }
        }
        > ul {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          > li {
            width: 35px;
            height: 35px;
            position: relative;
            background-color: $light;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            &:nth-child(n + 2) {
              margin-left: 15px;
            }
            &:last-child {
              background-color: transparent;
              width: auto;

              .d-flex {
                align-items: center;
              }
            }
            .badge {
              position: absolute;
              right: -5px;
              top: -6px;
              padding: 2px 4px;
              font-size: 11px;
              font-weight: 700;
              font-family: work-Sans, sans-serif;
            }
            span {
              ul {
                left: inherit;
                right: -10px;
                width: 130px;
                &:before,
                &:after {
                  left: inherit;
                  right: 10px;
                }
                li {
                  display: block;
                  a {
                    font-size: 14px;
                    color: $dark-color;
                    i {
                      margin-left: 10px;
                      font-size: 13px;
                      color: $dark-color;
                    }
                  }
                }
              }
            }
          }
          .flag-icon {
            font-size: 16px;
          }
        }
        .bookmark-flip {
          width: unset !important;
          box-shadow: unset !important;
          background-color: transparent !important;
          overflow: initial;
        }
        .flip-card {
          width: 220px;
          height: 245px;
          background-color: transparent;
          .flip-card-inner {
            position: relative;
            height: 100%;
            text-align: center;
            transition: transform 0.6s;
            transform-style: preserve-3d;
            .front,
            .back {
              position: absolute;
              backface-visibility: hidden;
              border-radius: 10px;
              overflow: hidden;
              box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
              width: 220px;
              height: auto;
              button {
                background-color: $transparent-color;
                color: $primary-color;
                border: none;
                &:focus {
                  outline: $transparent-color;
                }
              }
            }
            .front {
              background-color: $white;
              transition: 0.3s;
              svg {
                margin-bottom: 15px;
                stroke: $dark-color;
                transition: all 0.3s ease;
                &:hover {
                  stroke: var(--theme-default);
                  transition: all 0.3s ease;
                }
              }
            }
            .back {
              background-color: $white;
              transform: rotateY(180deg);
              li {
                &:first-child {
                  height: 160px !important;
                }
                &:last-child {
                  padding: 20px 0;
                  border-top: 1px solid $light-gray;
                }
              }
              button {
                width: 100%;
              }
              .flip-back-content {
                .Typeahead-menu{
                  max-height: 115px;
                }
                input {
                  border-radius: 8px;
                  padding: 6px 12px;
                  width: 100%;
                  border: 1px solid $light-gray;
                  &:focus {
                    outline: unset;
                  }
                }
              }
            }
            &.flipped {
              transform: rotateY(180deg);
              .front {
                opacity: 0;
              }
            }
          }
        }
        .onhover-show-div:not(.profile-dropdown) {
          li {
            &:first-child {
              padding: 20px;
              background-color: $white;
              svg {
                position: absolute;
                width: 50px;
                height: 50px;
                top: 0;
                right: 0;
                opacity: 0.05;
              }
            }
          }
        }
        .onhover-show-div {
          width: 330px;
          top: 50px;
          right: 0;
          left: unset;
          li {
            padding: 0 20px;
            p {
              opacity: 0.6;
            }
            & + li {
              padding-top: 15px;
              padding-bottom: 0px;
              border-top: 1px solid $light-gray;
            }
            &:last-child {
              padding-top: 15px;
              padding-bottom: 15px;
            }
            .d-flex {
              position: relative;
              img {
                width: 40px;
                position: relative;
              }
              .status-circle {
                left: 0;
              }
              .flex-grow-1 {
                > span {
                  letter-spacing: 0.8px;
                  padding-right: 10px;
                  display: inline-block;
                }
                p {
                  margin-bottom: 15px;
                }
              }
            }
            .btn-primary {
              color: $white !important;
            }
          }
          &:before,
          &:after {
            right: 10px !important;
            left: unset !important;
          }
        }
        .notification-box {
          position: relative;
          svg {
            animation: tada 1.5s ease infinite;
          }
        }
        .notification-dropdown {
          top: 52px;
          width: 300px;
          right: -20px !important;
          left: unset;
          li {
            & + li {
              padding-top: 8px;
              padding-bottom: 8px;
              p {
                margin-bottom: 0;
              }
            }
            &:last-child {
              text-align: center;
              border-top: 1px solid #ecf3fa;
            }
            p {
              span {
                color: $light-font;
              }
            }
            a {
              color: unset;
            }
          }
          &:before,
          &:after {
            right: 28px !important;
            left: unset !important;
          }
        }
        .chat-dropdown {
          li {
            a {
              color: unset;
            }
            .d-flex {
              align-items: flex-start;
            }
          }
        }
        .profile-dropdown {
          width: 160px;
          top: 57px;
          left: 1px;
          padding: 0 10px;
          li {
            padding: 10px !important;
            a{
              color: $theme-body-font-color;
            }
            &:hover {
              a {
                color: $primary-color;
                span {
                  color: var(--theme-default);
                  transition: color 0.3s ease;
                }
                svg {
                  stroke: var(--theme-default);
                  transition: stroke 0.3s ease;
                  path {
                    stroke: var(--theme-default);
                    transition: stroke 0.3s ease;
                  }
                }
              }
            }
            &:last-child {
              padding-top: 10px !important;
            }
            span {
              color: $dark-color;
            }
            svg {
              width: 16px;
              vertical-align: bottom;
              margin-right: 10px;
              stroke: $dark-color;
            }
          }
        }
      }
    }
  }
  .page-body-wrapper {
    background-color: $light;
    .page-body {
      min-height: calc(100vh - #{$header-size});
      margin-top: $header-size;
      padding: $page-body-padding;
      position: relative;
      transition: 0.5s;
      &:before {
        animation: fadeIn 0.5s ease-in-out;
      }
    }
    .page-title {
      padding-top: 25px;
      padding-bottom: 25px;
      > .row {
        .col-6 {
          &:first-child {
            display: flex;
          }
        }
      }
      .row {
        align-items: center;
        h3 {
          font-size: $page-title-font-size;
          margin-bottom: $page-title-margin-bottom;
          text-transform: $page-title-text-tranform;
          font-weight: 600;
          small {
            display: $page-small-title-display;
            font-size: $page-small-title-font-size;
            margin-bottom: $page-small-title-margin-bottom;
            text-transform: $page-small-title-text-transform;
            color: $theme-body-sub-title-color;
          }
        }
      }
      .breadcrumb {
        background-color: $transparent-color;
        padding: 0;
        margin-bottom: 0;
        align-items: center;
        justify-content: flex-end;
        .breadcrumb-item {
          color: #132b5e;
          font-size: 20px;
          font-weight: 500;
          &:first-child {
            a {
              border-radius: 5px;
              padding: 7px;
              background-color: $light;
            }
          }
          &:nth-child(2) {
            // font-weight: 500;
          }
          & + .breadcrumb-item {
            &:before {
              color: #555;
              content: $breadcrumb-content;
            }
          }
          a {
            color: #132b5e;
            svg {
              width: 20px;
              height: 22px;
              vertical-align: text-top;
              color: #132b5e !important;
            }
          }
        }
      }
    }
  }
  &.horizontal-wrapper {
    [data-simplebar] {
      position: relative;
    }
    .footer {
      margin-left: 0;
    }
    .header-logo-wrapper {
      width: 150px;
      .toggle-sidebar {
        display: none;
      }
    }
    .left-header {
      padding-right: 0;
    }
    .page-body-wrapper {
      .sidebar-wrapper {
        height: 55px;
        line-height: inherit;
        top: 80px;
        background: #0a0911;
        box-shadow: 0px 9px 24px rgba($semi-dark, 0.05);
        overflow-x: hidden;
        z-index: 1;
        overflow-y: visible;
        position: fixed;
        width: 100vw;
        border-top: 1px solid $light-semi-gray;
        .badge {
          position: absolute;
          top: 2px;
          right: 0px;
          z-index: 1;
        }
        .logo-wrapper,
        .logo-icon-wrapper {
          display: none;
        }
        .sidebar-main {
          text-align: left;
          position: relative;
          .left-arrow,
          .right-arrow {
            position: absolute;
            top: 0px;
            padding: 15px;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            cursor: pointer;
            &.disabled {
              display: none;
            }
          }
          .left-arrow {
            left: 0;
          }
          .right-arrow {
            right: 10px;
          }
          .sidebar-links {
            list-style: none;
            margin: 0;
            padding: 0;
            line-height: normal;
            direction: ltr;
            text-align: left;
            display: -webkit-box !important;
            padding: 0 5px;
            transition: all 0.5s ease;
            white-space: nowrap;
            li {
              &.sidebar-list {
                a {
                  &.active {
                    .according-menu {
                      i {
                        &:before {
                          content: "-";
                        }
                      }
                    }
                  }
                  .according-menu {
                    display: none;
                  }
                }
                ul.sidebar-submenu {
                  li {
                    a {
                      &.active {
                        color: var(--theme-default) !important;
                        &:after {
                          background-color: var(--theme-default);
                        }
                      }
                    }
                  }
                }
                &:hover {
                  > a {
                    &:hover {
                      background-color: rgba($primary-color, 0.12%);
                      transition: all 0.3s ease;
                      > span.badge.badge-primary {
                        color: $white !important;
                      }
                    }
                  }
                }
              }
            }
            .simplebar-wrapper {
              .simplebar-mask {
                .simplebar-content-wrapper {
                  padding-bottom: 0;
                  .simplebar-content {
                    padding-bottom: 0 !important;
                    > li {
                      position: relative;
                      padding-top: 10px;
                      padding-bottom: 10px;
                      display: inline-block;
                      &:hover {
                        > a {
                          > span {
                            color: var(--theme-default);
                            transition: all 0.3s ease;
                          }
                        }
                        a {
                          background-color: rgba($primary-color, 0.15%);
                          transition: all 0.3s ease;
                          svg {
                            fill: rgba($primary-color, 0.1%);
                            stroke: var(--theme-default);
                            transition: all 0.3s ease;
                          }
                        }
                      }
                      &:first-child {
                        display: none !important;
                      }
                      > a {
                        display: block;
                        color: $theme-body-font-color;
                        line-height: 23px;
                        text-decoration: none;
                        text-transform: capitalize;
                        padding: 5px 15px;
                        margin: 0 3px;
                        letter-spacing: 0.04em;
                        position: relative;
                        border-radius: 5px;
                        font-weight: 500;
                        transition: all 0.3s ease;
                        &.active {
                          transition: all 0.3s ease;
                          svg,
                          span {
                            color: var(--theme-default);
                            transition: all 0.3s ease;
                          }
                        }
                        .badge {
                          position: absolute;
                          top: -5px;
                          margin-left: 1px;
                          right: 10px;
                          padding: 0.2em 0.7em;
                        }
                        svg {
                          width: 16px;
                          vertical-align: bottom;
                          margin-right: 10px;
                          stroke-width: 2.5px;
                        }
                      }
                      .sidebar-submenu {
                        position: fixed;
                        z-index: 4;
                        box-shadow: 0 0 1px 0 $dark-gray;
                        padding: 15px 0px 15px 0px;
                        background: white;
                        li {
                          border: 0;
                          float: none;
                          position: relative;
                          display: block;
                          a {
                            background: transparent;
                            padding: 4px 30px;
                            display: block;
                            transition: all 0.5s ease;
                            line-height: 1.9;
                            color: rgba(43, 43, 43, 0.6);
                            letter-spacing: 0.06em;
                            font-weight: 500;
                            font-family: Roboto;
                            transition: all 0.3s ease;
                            &.active{
                              &:after{
                                background-color: $primary-color;
                              }
                            }
                            &:after {
                              left: 20px;
                              @extend %horizontal-after;
                            }
                            &:hover {
                              color: var(--theme-default);
                              letter-spacing: 1.5px;
                              transition: all 0.3s ease;
                              &:after {
                                background-color: var(--theme-default);
                              }
                            }
                          }
                        }
                      }
                      &:hover {
                        > a {
                          &:after {
                            left: 0;
                            right: 0;
                            opacity: 1;
                          }
                        }
                      }
                      &.mega-menu {
                        position: unset;
                      }
                    }
                  }
                }
              }
            }
          }
          .sidebar-img-section {
            display: none;
          }
        }
        &.overlay-white {
          background-color: rgba(255, 255, 255, 0.08);
        }
      }
      .toggle-nav {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
          font-size: 24px;
        }
      }
      .page-body {
        margin-top: 135px;
      }
      .sidebar-main-title {
        display: none !important;
      }
      &.scorlled {
        .sidebar-wrapper {
          display: none;
          transition: all 0.3s ease;
        }
      }
    }
    .page-header {
      width: 100%;
      box-shadow: none;
      .header-wrapper {
        .form-control-plaintext {
          width: auto !important;
        }
      }
    }
  }
  .sidebar-main-title {
    > div {
      background-color: $light;
      padding: 15px 17px;
      border-radius: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    h4 {
      color: var(--theme-default);
      letter-spacing: 0.4px;
      margin-bottom: 0px;
    }
  }
  // left sidebar css
  &.compact-wrapper {
    .page-header {
      margin-left: 258px;
      width: calc(100% - 258px);
      &.close_icon {
        margin-left: $sidebar-compact;
        width: calc(100% - $sidebar-compact);
      }
      .header-wrapper {
        .logo-wrapper {
          display: none;
        }
        .toggle-sidebar {
          display: none;
        }
      }
    }
    .page-body-wrapper {
      div {
        &.sidebar-wrapper {
          position: fixed;
          top: 0;
          z-index: 9;
          line-height: inherit;
          background: #132b5e;
          color: #132b5e;
          width: 258px;
          text-align: left;
          transition: 0.3s;
          box-shadow: 0 0 21px 0 rgba($semi-dark, 0.1);
          &.close_icon{
            &:hover{
              .logo-wrapper,
              .logo-icon-wrapper {
                padding: 22px 30px;
              }
            }
            .logo-wrapper,
            .logo-icon-wrapper {
              padding: 27px 30px;
            }
          }
          .logo-wrapper,
          .logo-icon-wrapper {
            padding: 20px 30px 15px 30px;
            box-shadow: -9px 0 20px rgba($semi-dark, 0.1);
          }
          .logo-icon-wrapper {
            display: none;
          }
          &.close_icon {
            width: auto;
            transition: all 0.3s ease;
            .badge {
              opacity: 0;
              width: 0;
              padding: 0;
            }
            .sidebar-main {
              .sidebar-links {
                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        padding-top: 30px;
                        padding-bottom: 30px !important;
                        > li {
                          max-width: 86px;
                          margin: 0;
                          transition: all 0.3s ease;
                          .sidebar-submenu {
                            a {
                              display: none;
                            }
                          }
                          a {
                            padding: 12px 14px;
                            transition: all 0.3s ease;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .sidebar-main-title {
              display: none !important;
              opacity: 0;
              transition: all 0.3s ease;
            }
            .logo-wrapper {
              display: none;
            }
            .logo-icon-wrapper {
              text-align: right;
              display: block;
              img {
                width: auto;
                height: 25px;
              }
            }
            &:hover {
              width: 258px;
              transition: all 0.3s ease;
              .badge {
                opacity: 1;
                width: auto;
                transition: all 0.3s ease;
              }
              .sidebar-main {
                .sidebar-img-section {
                  display: block;
                }
                .simplebar-offset {
                  height: calc(150vh - 350px);
                }
                .sidebar-links {
                  height: calc(150vh - 350px);
                  .simplebar-wrapper {
                    .simplebar-mask {
                      .simplebar-content-wrapper {
                        .simplebar-content {
                          > li {
                            max-width: unset;
                            margin: initial;
                            transition: all 0.3s ease;
                            .sidebar-submenu {
                              a {
                                display: block;
                              }
                            }
                            > a {
                              padding: 12px 15px;
                              transition: all 0.3s ease;
                              span {
                                display: inline-block;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .sidebar-main-title {
                opacity: 1;
                display: block !important;
                transition: all 0.3s ease;
              }
              .logo-wrapper {
                display: block;
              }
              .logo-icon-wrapper {
                display: none;
              }
            }
            .mobile-sidebar {
              transform: translate(183px);
            }
            ~ .page-body,
            ~ footer {
              margin-left: $sidebar-compact;
              transition: 0.5s;
            }
            ~ .footer-fix {
              width: calc(100% - $sidebar-compact);
            }
            .sidebar-main {
              .simplebar-offset {
                height: 100vh;
              }
              .sidebar-links {
                height: 100vh;
                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        > li {
                          .sidebar-link {
                            &::before {
                              right: 20px;
                              left: unset;
                            }
                          }
                          a {
                            span {
                              display: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .sidebar-list {
                  .sidebar-title {
                    &.active {
                      ~ .sidebar-submenu {
                        display: none;
                      }
                    }
                  }
                }
              }
              .sidebar-img-section {
                display: none;
              }
            }
            .according-menu {
              display: none;
            }
          }
          .sidebar-main {
            .left-arrow,
            .right-arrow {
              display: none;
            }
            .sidebar-links {
              height: $sidebar-height;
              left: -300px;
              z-index: 99;
              transition: color 1s ease;
              color: rgba(0, 0, 0, 0);
              margin-top: 20px;
              margin-bottom: 60px;
              padding-bottom: 30px;
              li {
                a {
                  font-weight: 500;
                  &.active {
                    div {
                      svg {
                        color: var(--theme-default);
                        stroke: var(--theme-default);
                      }
                    }
                    span{
                      color: $primary-color;
                    }
                  }
                  span {
                    letter-spacing: 0.7px;
                    font-family: $font-roboto;
                    text-transform: capitalize;
                    color: #fff;
                    
                  }
                  &.link-nav {
                    .according-menu {
                      display: none;
                    }
                  }
                }
                &:last-child {
                  border-bottom: none;
                }
              }
              &.opennav {
                left: 0;
              }
              .back-btn {
                display: block;
                width: 100%;
                padding: 0;
                .mobile-back {
                  padding: 20px;
                  color: $theme-font-color;
                  font-weight: 700;
                  text-transform: uppercase;
                  border-bottom: 1px solid #efefef;
                  cursor: pointer;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                }
              }
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      > li {
                        display: block;
                        width: 100%;
                        padding: 0px;
                        position: relative;
                        &.sidebar-main-title {
                          padding-top: 15px;
                          padding-bottom: 13px;
                        }
                        &.sidebar-list {
                          .badge {
                            position: absolute;
                            right: 36px;
                            top: 16px;
                            padding: 4px 6px 3px;
                            z-index: 1;
                            color: $white;
                            border-radius: 50%;
                          }
                          &:hover {
                            > a {
                              &:hover {
                                transition: all 0.3s ease;
                                > span.badge.badge-primary {
                                  color: $white !important;
                                }
                              }
                            }
                          }
                        }
                        &:hover {
                          .sidebar-link:not(.active) {
                            &:hover {
                              svg {
                                fill: rgba($primary-color, 0.1%);
                                stroke: var(--theme-default);
                                transition: all 0.3s ease;
                              }
                              span {
                                color: var(--theme-default);
                                transition: all 0.3s ease;
                              }
                              .according-menu {
                                i {
                                  color: var(--theme-default);
                                  transition: all 0.3s ease;
                                }
                              }
                            }
                          }
                        }
                        a {
                          padding: 11px 15px;
                          position: relative;
                          color: #222222;
                          transition: all 0.3s ease;
                          svg {
                            width: 18px;
                            margin-right: 10px;
                            vertical-align: bottom;
                            float: none;
                            stroke-width: 2.3px;
                            color: #fff;
                            transition: all 0.3s ease;
                            margin-bottom: 5px;
                          }
                          .sub-arrow {
                            right: 20px;
                            position: absolute;
                            top: 10px;
                            i {
                              display: none;
                            }
                            &:before {
                              display: none;
                            }
                          }
                        }
                        &:first-child {
                          display: none !important;
                        }
                        .sidebar-submenu {
                          width: 100%;
                          padding: 0;
                          position: relative !important;
                          > li {
                            a {
                              padding-left: 30px !important;
                              font-weight: 400;
                            }
                          }
                          .submenu-title {
                            .according-menu {
                              top: 7px;
                            }
                          }
                          &.opensubmenu {
                            display: block;
                          }
                          li {
                            &:hover {
                              > a {
                                color: var(--theme-default);
                                transition: all 0.3s ease;
                                &:after {
                                  border-top: 2px solid var(--theme-default);
                                  transition: all 0.3s ease;
                                }
                              }
                            }
                            .nav-sub-childmenu {
                              display: none;
                              position: relative !important;
                              right: 0;
                              width: 100%;
                              padding: 0;
                              &.opensubchild {
                                display: block;
                              }
                              li {
                                a {
                                  // content: "-";
                                  padding: 3px 10px;
                                  padding-left: 50px !important;
                                  &:after {
                                    display: none;
                                  }
                                }
                              }
                            }
                            a {
                              padding: 7px 25px;
                              font-size: 14px;
                              color: #555;
                              display: block;
                              position: relative;
                              letter-spacing: 0.06em;
                              font-weight: 500;
                              font-family: Roboto;
                              transition: all 0.3s ease;
                              white-space: nowrap;
                              overflow: hidden;
                              text-transform: capitalize;
                              text-overflow: ellipsis;
                              &.active {
                                color: var(--theme-default);
                                font-weight: 800;
                                svg {
                                  color: var(--theme-default) !important;
                                  stroke: var(--theme-default);
                                }
                                &::after{
                                  border-top: 2px solid $primary-color;
                                }
                              }
                              &:after {
                                left: 20px;
                                @extend %sidebar-after;
                              }
                              &:hover {
                                margin-left: 0;
                              }
                            }
                          }
                        }
                        .sidebar-link {
                          border-radius: 0px;
                          transition: all 0.5s ease;
                          display: block;
                          font-size: 16px;
                          &.active,
                          &:hover {
                            background-color: rgb(255 255 255 / 93%);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .sidebar-img-section {
              padding: 0 22px;
              margin-bottom: 50px;
              .sidebar-img-content {
                background-color: $light;
                height: 100%;
                position: relative;
                border-radius: 20px;
                text-align: center;
                padding-bottom: 20px;
                img {
                  margin-top: -48px;
                  height: 100px;
                }
                h4 {
                  font-weight: 500;
                  font-size: 16px;
                  margin-bottom: 10px;
                  margin-top: 10px;
                  line-height: 1.4;
                }
                .btn {
                  padding: 10px 15px;
                  line-height: 1;
                  &:hover {
                    background-color: $white !important;
                    border: 1px solid $primary-color;
                    color: $primary-color;
                  }
                }
              }
            }
            .page-wrapper {
              &.horizontal-wrapper {
                .sidebar-img-section {
                  display: none;
                }
              }
            }
            .simplebar-offset {
              height: $sidebar-height;
            }
          }
        }
      }
      .sidebar-submenu{
        .according-menu{
          top: 6px;
        }
      }
      .according-menu {
        position: absolute;
        right: 15px;
        top: 10px;
        display: block;
        i {
          vertical-align: middle;
          color: #fff;
          font-weight: 600;
        }
      }
      .main-menu {
        &.border-section {
          border: none;
        }
        .menu-left {
          .main-menu-right {
            .toggle-nav {
              position: absolute;
              z-index: 2;
              right: 145px;
              top: 6px;
              .sidebar-name {
                font-size: 18px;
                padding-left: 5px;
                text-transform: uppercase;
              }
            }
          }
        }
      }
      .page-body {
        min-height: calc(100vh - 95px);
        margin-top: 56px;
        margin-left: 258px;
      }
    }
    &.dark-sidebar {
      .toggle-sidebar {
        svg {
          stroke: var(--theme-default);
        }
      }
      div.sidebar-wrapper {
        background-color: $dark-card-background;
        .sidebar-main {
          .sidebar-links {
            li {
              a {
                span {
                  color: $dark-all-font-color;
                }
                svg {
                  stroke: $dark-all-font-color;
                }
              }
            }
            .simplebar-wrapper {
              .simplebar-mask {
                .simplebar-content-wrapper {
                  .simplebar-content {
                    > li {
                      .sidebar-link {
                        &.active {
                          background-color: rgba($primary-color, 0.2);
                          
                          svg {
                            color: var(--theme-default);
                            stroke: var(--theme-default);
                          }
                        }
                      }
                      .sidebar-submenu {
                        li {
                          a {
                            color: $dark-all-font-color;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .sidebar-list {
              ul.sidebar-submenu {
                li {
                  a {
                    span {
                      color: $dark-all-font-color;
                    }
                    &.active {
                      color: var(--theme-default);
                    }
                  }
                }
              }
            }
          }
        }
        .logo-wrapper {
          .for-light {
            display: none;
          }
          .for-dark {
            display: unset;
          }
        }
      }
      .according-menu {
        i {
          color: #132b5e;
        }
      }
      .sidebar-main-title {
        p {
          color: $dark-all-font-color;
        }
      }
    }
  }
}
@keyframes pagebody {
  from {
    opacity: 0;
    transform: scale3d(0.98, 0.98, 0.98) translate(-20px, -50px);
  }
  50% {
    opacity: 1;
  }
}
@media screen and (max-width: 1366px) {
  .profile-dropdown {
    &.onhover-show-div {
      right: 0;
      left: unset !important;
      &:before,
      &:after {
        right: 10px !important;
        left: unset !important;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .mobile-title {
    &.d-none {
      display: flex !important;
      justify-content: space-between;
      padding: 20px 30px 17px;
      border-bottom: 1px solid #ddd;
      margin: 0 -15px;
      h5 {
        color: var(--theme-default);
      }
      svg {
        stroke: var(--theme-default) !important;
      }
    }
  }
}
@media screen and (min-width: 992px) {
  .page-header {
    .header-wrapper {
      .nav-right {
        .notification-dropdown {
          top: 46px;
        }
      }
    }
  }
  .responsive-btn {
    display: none;
  }
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        .sidebar-main {
          .sidebar-links {
            .simplebar-mask {
              top: 0;
            }
            .simplebar-wrapper {
              .simplebar-mask {
                .simplebar-content-wrapper {
                  .simplebar-content {
                    > li {
                      .sidebar-submenu {
                        width: 218px;
                        opacity: 0;
                        visibility: hidden;
                        border-radius: 5px;
                        transition: all 0.5s ease;
                        a {
                          .sub-arrow {
                            position: absolute;
                            right: 20px;
                            top: 5px;
                            color: $light-semi-font;
                            font-weight: 100;
                            transition: all 0.5s ease;
                            font-size: 15px;
                            top: 5px;
                          }
                        }
                        li {
                          .nav-sub-childmenu {
                            display: none;
                            border-radius: 4px;
                          }
                          &:hover {
                            .nav-sub-childmenu {
                              display: block;
                              position: absolute;
                              left: 200px;
                              background-color: $white;
                              top: -10px;
                              padding: 15px 0px 15px 0px;
                              box-shadow: 0 0 1px 0 #898989;
                            }
                          }
                          a {
                            &:hover {
                              .sub-arrow {
                                right: 16px;
                                transition: all 0.5s ease;
                              }
                            }
                          }
                        }
                      }
                      &.hoverd {
                        .sidebar-submenu {
                          opacity: 1;
                          visibility: visible;
                          margin-top: 8px;
                          transition: all 0.5s ease;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .toggle-nav {
          display: none;
        }
        .mobile-back {
          display: none !important;
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .page-wrapper {
    &.compact-wrapper{
      .page-body-wrapper{
        div.sidebar-wrapper{
          .sidebar-main{
            .sidebar-links{
              height: calc(140vh - 370px);
            }
            .simplebar-offset{
              height: calc(100vh - 306px);
            }
          }
        }
      }
    }
    .page-header {
      .header-wrapper {
        .nav-right {
          .profile-dropdown {
            top: 45px;
            width: 160px;
          }
        }
      }
    }
  }
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          > ul {
            > li {
              padding: 8px;
              &:last-child {
                .d-flex {
                  img {
                    width: 30px;
                    height: auto;
                  }
                }
              }
              .profile-media {
                .flex-grow-1 {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .sidebar-wrapper {
      .sidebar-main {
        #sidebar-menu {
          .sidebar-img-section {
            .sidebar-img-content {
              img {
                height: 100px !important;
                margin-top: -16px !important;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .sidebar-wrapper {
    .menu {
      .brand-logo {
        img {
          width: 120px;
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .toggle-nav {
    i {
      font-size: 20px;
    }
  }
}
.carousal-page .card-body .slick-slider .slick-slide > div:first-child {
  margin: 0 5px;
  img {
    border-radius: 5px;
  }
}
/**=====================
    34. Sidebar CSS Ends
==========================**/