/**=====================
    41. Coming Soon CSS Start
==========================**/
.comingsoon-bgimg {
  // background: url(../../images/other-images/coming-soon-bg.jpg);
  background-position: bottom;
  background-size: cover;
}
.comingsoon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 50px 0;
  .logo-wrraper {
    img {
      width: auto;
    }
  }
  .comingsoon-inner {
    h5 {
      font-size: 30px;
      letter-spacing: 1px;
      color: $secondary-color;
      font-weight: 700;
      margin-top: 30px;
    }
    h6 {
      font-size: 20px;
      letter-spacing: 1px;
      color: $primary-color;
      font-weight: 500;
    }
    .countdown {
      padding: 30px 0;
      border-top: 1px solid $gray-60;
      border-bottom: 1px solid $gray-60;
      .time {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: #fff;
        font-weight: 600;
        width: 80px;
        height: 80px;
        font-size: 36px;
        background: var(--theme-default);
        border-radius: 20px;
      }
      .title {
        padding-top: 8px;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
      ul li {
        display: inline-block;
        text-transform: uppercase;
        margin: 0 20px;

        span {
          display: block;
        }
      }
    }
    .svg-wrapper {
      img {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 420px;
      }
    }
  }
  video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    transition: 1s opacity;
    opacity: 0.3 !important;
  }
}
/**=====================
    41. Coming Soon CSS Ends
==========================**/