/**=====================
    58. Authentication CSS Start
==========================**/
.login-card {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 0 auto;
  background: rgba($primary-color, 0.14);
  background-position: center;
  padding-left: 15rem;
  .logo {
    display: block;
    margin-bottom: 30px;
    text-align: center;
  }
  .btn-showcase {
    .btn {
      line-height: 1;
      padding: 10px 15px;
      margin: 0;
      & + .btn {
        margin-left: 5px;
      }
      svg {
        height: 16px;
        vertical-align: bottom;
      }
    }
  }
  .login-main {
    width: 450px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
    margin: 0 auto;
    background-color: $white;
    .theme-form {
      h4 {
        margin-bottom: 5px;
      }
      .log-unlock {
        width: 75%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      label {
        font-size: 15px;
        letter-spacing: 0.4px;
      }
      .checkbox label::before {
        background-color: $card-body-color;
        border: 1px solid $light-gray;
      }
      .login-social-title {
        position: relative;
        z-index: 1;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        h3 {
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
          color: $light-text;
          background-color: $white;
          padding-left: 25px;
          padding-right: 25px;
          font-size: 15px;
        }
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: $light-gray;
          top: 10px;
          z-index: -1;
          right: 0;
        }
      }
      input {
        transition: all 0.3s ease;
        border: 1px solid $light;
        &::-webkit-input-placeholder {
          color: $light-text;
        }
        &:hover,
        &:focus {
          box-shadow: none !important;
          transition: all 0.3s ease;
        }
      }
      p {
        margin-bottom: 25px;
        font-size: 14px;
        color: $dark-gray;
      }
      .form-group {
        margin-bottom: 10px !important;
        position: relative;
      }
      .link {
        position: absolute;
        top: 10px;
        right: 0;
      }
      ul.login-social {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
          width: 40px;
          height: 40px;
          // border-radius: 50%;
          // border: 1px solid $light-gray;
          // background-color: rgba($primary-color, 0.05);
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 2rem !important;
          }
          &:nth-child(n + 2) {
            margin-left: 15px;
          }
          &:hover {
            background-color: $primary-color;
            a {
              color: $white !important;
            }
          }
        }
      }
    }
  }
}
.show-hide {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  span {
    cursor: pointer;
    font-size: 13px;
    color: var(--theme-default);
    &.show {
      &:before {
        // content: "show";
      }
    }
    &:before {
      // content: "hide";
    }
  }
}
.needs-validation {
  .invalid-feedback {
    color: $dark-gray;
  }
  .show-hide {
    right: 30px;
  }
  .invalid-tooltip {
    top: 0;
    right: 10px;
  }
}
/**=====================
    58. Authentication CSS Ends
==========================**/