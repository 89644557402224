/**=====================
    25. Table  CSS Start
==========================**/
thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: $light-gray;
}
.pills-component {
  width: 20%;
}
.table {
  margin-bottom: $table-b-margin;
  > :not(:last-child) {
    > :last-child {
      > * {
        border-bottom-color: $light-gray;
      }
    }
  }
  th,
  td {
    padding: $table-padding;
  }
  &.table-primary {
    th {
      color: $white;
    }
    td {
      color: $white;
    }
  }
  thead {
    &.bg-primary {
      tr {
        th {
          color: $white;
        }
      }
    }
    &.table-primary {
      tr {
        th {
          color: $primary-color;
        }
      }
    }
    &.table-success {
      tr {
        th {
          color: $success-color;
        }
      }
    }
  }
  tfoot {
    &.table-success {
      tr {
        td,
        th {
          color: $success-color;
        }
      }
    }
  }
  th {
    color: #0d0b50;
    font-weight: 500;
  }
  td {
    color: $table-row-color;
  }

  [class*="bg-"] {
    color: $white;
  }
  .bg-light {
    color: $theme-body-font-color;
  }
  tfoot {
    font-weight: $table-footer-font-weight;
  }
}
.table-striped{
  &.table-hover{
    tbody{
      tr{
        &:hover{
          th, td{
            color: $white;
          }
        }
      }
    }
  }
  &.table-styling{
    > tbody{
      > tr{
        &:nth-of-type(odd){
          &:hover{
            > * {
              color: $primary-color;
            }
          }
          > * {
            --bs-table-accent-bg: none;
            color: $white;
          }
        }
      }
    }
  }
  &.bg-primary{
    > tbody{
      > tr{
        &:nth-of-type(odd){
          * {
            color: $white;
          }
        }
      }
    }
  }
}
.table-styling {
  &.table-primary {
    &.table-striped {
      tbody {
        tr {
          color: $white;
          &:nth-of-type(odd) {
            --bs-table-accent-bg: unset;
            &:hover {
              color: var(--theme-default);
            }
          }
          &:nth-of-type(even) {
            &:hover {
              th,
              td {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
.table-responsive{
  tfoot{
    &.table-success{
      border-top: 0;
    }
  }
  .table-striped{
    tbody{
      tr{
        &:last-child{
          th{
            &:first-child{
              border-radius: 0 0 0 15px;
            }
          }
          td{
            &:last-child{
              border-radius: 0 0 15px 0;
            }
          }
        }
      }
    }
  }
  .table-inverse, .table-striped, .table-borderedfor{
    border-radius: 0 0 15px 15px;
  }
  .table-borderedfor{
    .table-active{
      &:last-child{
        td{
          &:last-child{
            border-radius: 0 0 15px 0;
          }
          &:first-child{
            border-radius: 0 0 0 15px;
          }
        }
      }
      td{
        &:last-child{
          border-radius: 0;
        }
      }
    }
  }
  .table-active{
    th{
      &:first-child{
        border-radius: 0 0 0 15px;
      }
    }
    td{
      &:last-child{
        border-radius: 0 0 15px 0;
      }
    }
  }
}
.table-hover {
  tbody {
    td{
      vertical-align: middle;
    }
    tr:hover {
      background-color: rgba($primary-color, 0.1);
      --bs-table-accent-bg: unset;
      --bs-table-hover-bg: none;
      th,
      td {
        color: var(--theme-default);
      }
    }
  }
}
.table-dark {
  th {
    color: $white;
  }
}
.basic_table{
  .table-responsive{
    .table {
      &.table-hover{
        tbody {
          tr{
            &:last-child{
              th,td{
                border-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
.table-inverse {
  color: $white;
  background-color: $table-inverse-bg-color;
  th,
  td {
    border-color: $white;
    color: $white;
  }
  &.table-bordered {
    border: 0;
  }
  thead{
    tr{
      th{
        border-bottom: 1px solid $white !important;
      }
    }
  }
}
.table[class*="bg-"] {
  th,
  td {
    color: $white;
  }
}
.table-hover {
  > tbody {
    > tr:hover {
      --bs-table-accent-bg: unset;
    }
  }
}
.table-double {
  border-left: 4px double #dee2e6;
  border-right: 4px double #dee2e6;
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-style: double;
    border-width: 4px;
    border-right: 0;
    border-left: 0;
  }
}
.table-dotted {
  border-left: 2px dotted #dee2e6;
  border-right: 2px dotted #dee2e6;
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-style: dotted !important;
    border-width: 2px;
    border-right: 0;
    border-left: 0;
  }
}
.table-dashed {
  border-left: 2px dashed #dee2e6;
  border-right: 2px dashed #dee2e6;
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-style: dashed !important;
    border-width: 2px;
    border-right: 0;
    border-left: 0;
  }
}
//Sizing class
.table-xl {
  td,
  th {
    padding: $table-xl-padding;
    font-size: $table-xl-font;
  }
}
.table-lg {
  td,
  th {
    padding: $table-lg-padding;
    font-size: $table-lg-font;
  }
}
.table-de {
  td,
  th {
    padding: $table-de-padding;
  }
}
.table-sm {
  th,
  td {
    padding: $table-sm-padding;
    font-size: $table-sm-font;
  }
}
.table-xs {
  th,
  td {
    padding: $table-xs-padding;
    font-size: $table-xs-font;
  }
}
//Table Border
.table-border-horizontal {
  tr,
  th,
  td {
    border-top: 1px solid $horizontal-border-color;
    padding: $horizontal-padding;
  }
}
.table-border-vertical {
  tr,
  th,
  td {
    border-top: 0;
    border-right: 1px solid $horizontal-border-color;
  }
  tr {
    td {
      border-bottom: 0;

      &:last-child {
        border-right: none;
      }
    }
  }
  tbody {
    tr {
      th {
        border-bottom: 0;
      }
    }
  }
}
.table-bordernone {
  td {
    border: none !important;
  }
  thead {
    th {
      border: none !important;
    }
  }
  .u-s-tb {
    padding: 11px;
  }
}
.table-striped {
  tbody {
    tr {
      &:nth-of-type(odd) {
        --bs-table-accent-bg: rgba(245, 247, 250, 0.5);
      }
    }
  }
}
.table-bordered {
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: $light-gray;
  }
}
.grid-wrrapper {
  .table-striped {
    tbody {
      tr {
        &:nth-of-type(odd) {
          background-color: rgba($primary-color, 0.1);
          --bs-table-accent-bg: unset;
          th,
          td {
            color: $primary-color;
          }
        }
      }
    }
  }
}
/**=====================
     25.Table CSS Ends
==========================**/